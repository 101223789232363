/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Button, Icon, createTheme, withDefaultProps } from "@wsui/base";
import { Fragment } from "react";

import { DefaultLink as FooterLink } from "../@whitespace/gatsby-theme-wordpress-basic/wsui/components/Footer.jsx";
import FeatureBillboardModule from "../FeatureBillboardModule.jsx";
import HeroBillboardModule from "../HeroBillboardModule.jsx";
import QuoteBillboardModule from "../QuoteBillboardModule.jsx";

import "./index.css";

export default createTheme({
  colors: {
    aubergine: "#401834",
    plommon: "#873B52",
    nougat: "#A1715F",
    ek: "#D3A973",
    mynta: {
      main: "#9BC7B5", //web complementary mynta, original was "#71A691",
      // text: "white",
      hover: "linblomma.hover",
      active: "linblomma.active",
    },
    linblomma: { main: "#BCD6AB", hover: "-50", active: "main.+100" },
    bjork: "#F4EFD7",
    gray: "bjork",
    primary: "aubergine",
    alert: "#f96e20",
    error: "alert",
    warning: "alert",
    success: "gray",
    info: "gray",
    border: "gray.100",
    white: { main: "bjork.20" },
    black: "aubergine",
  },
  textColors: ["bjork", "white", "aubergine"],
  linkColors: ["bjork", "white", "mynta", "aubergine"],
  typography: {
    fonts: {
      // Inter: "/fonts/Inter.var.woff2",
      // "Open Sans": {
      //   src: {
      //     normal:
      //       'url("/fonts/OpenSans-VariableFont_wdth,wght.ttf") format("truetype")',
      //     italic:
      //       'url("/fonts/OpenSans-Italix-VariableFont_wdth,wght.ttf") format("truetype")',
      //   },
      // },
      Hellix: "/fonts/Hellix-SemiBold.woff2",
      // "Hellix-Medium": "/fonts/Hellix-Medium.woff2",
      // "Hellix-SemiBold": "/fonts/Hellix-SemiBold.woff2",
      // Hellix: {
      //   medium: "/fonts/Hellix-Medium.woff2",
      //   semiBold: "/fonts/Hellix-SemiBold.woff2",
      // },
    },
    // fontFamily: "Georgia, serif",
    variants: {
      h1: {
        fontFamily: "Hellix",
        fontSize: [10, 15],
        lineHeight: [10.5, 15],
        fontWeight: 600,
        marginBlockStart: [9.25, 15],
        marginBlockEnd: [9.25 / 2, 15 / 2],
      },
      h2: {
        fontFamily: "Hellix",
        fontSize: [6.25, 10],
        lineHeight: [6.75, 11.25],
        fontWeight: 600,
        marginBlockStart: [9.25, 12],
        marginBlockEnd: [9.25 / 2, 12 / 2],
      },
      h3: {
        fontFamily: "Hellix",
        fontSize: [5, 6],
        lineHeight: [6.5, 7.5],
        fontWeight: 600,
        marginBlockStart: [6.5, 6.5],
        marginBlockEnd: [6.5 / 2, 6.5 / 2],
      },
      h4: {
        fontFamily: "Hellix",
        fontSize: [4.5, 4.5],
        lineHeight: [7, 7],
        fontWeight: 600,
        marginBlockStart: [5.75, 7.5],
        marginBlockEnd: [5.75 / 2, 7.5 / 2],
      },
      h5: {
        fontFamily: "Hellix",
        fontSize: [4.5, 4.5],
        lineHeight: [7, 7],
        fontWeight: 600,
        marginBlockStart: [5.75, 7.5],
        marginBlockEnd: [5.75 / 2, 7.5 / 2],
      },
      h6: {
        fontFamily: "Hellix",
        fontSize: [4.5, 4.5],
        lineHeight: [7, 7],
        fontWeight: 600,
        marginBlockStart: [5.75, 7.5],
        marginBlockEnd: [5.75 / 2, 7.5 / 2],
      },
      preamble: {
        fontSize: [5, 6],
        lineHeight: [6.5, 7.5],
        fontWeight: 700,
      },
      body: {
        fontFamily: "Georgia, serif",
        fontSize: 4.5,
        lineHeight: [7, 7.5],
        fontWeight: 400,
        // marginBlockStart: 0,
        // marginBlockEnd: [26 / 4, 35 / 4],
      },
      quote: {
        fontFamily: "Hellix",
        fontSize: [5, 7.5],
        lineHeight: [6.75, 9.25],
        fontWeight: 600,
      },
      // formLabel: {
      //   fontSize: [4, 4],
      //   lineHeight: [6.75, 6.75],
      //   fontWeight: 500,
      // },
      // formDescription: {
      //   fontSize: [3, 3],
      //   lineHeight: [6.75, 6.75],
      // },
      // caption: {
      //   fontWeight: 400,
      // },
      description: {
        fontFamily: "Georgia, serif",
        fontSize: [4, 4],
        lineHeight: [6.75, 6.75],
        fontWeight: 400,
      },
      // button: {
      //   fontSize: [4, 4],
      //   lineHeight: [6.75, 6.75],
      //   fontWeight: 600,
      // },
    },
  },
  components: {
    Accordion: {
      defaultProps: {
        marginBefore: true,
      },
    },
    Alert: {
      defaultProps: {
        iconMapping: {},
      },
    },
    AlertBanner: {
      defaultProps: {
        components: {
          ReadMore: {
            color: "gray",
          },
        },
      },
    },
    Breadcrumbs: {
      defaultProps: {
        description: <Icon name="star" size={8} />,
        fontSize: 3.5,
        style: {
          "--icon-color": "aubergine",
          color: "mynta.500",
          display: "flex",
          alignItems: "center",
          "& > ol > li:last-child": {
            color: "aubergine",
          },
        },
      },
    },

    Button: {
      variants: {
        primary: {
          color: "mynta",
          style: {
            fontWeight: 600,
            color: "aubergine",
          },
        },
        secondary: {
          color: "linblomma",
          style: {
            fontWeight: 600,
          },
        },
        tertiary: {
          color: "transparent",
          style: {
            color: "link",
            fontWeight: 600,
            textDecoration: "underline",
          },
          hoverStyle: {
            color: "link",
            textDecoration: "none",
          },
          append: <Icon name="arrow-right" size={3.5} />,
        },
        hamburger: {
          color: "primary",
        },
        submit: {
          color: "linblomma",
          style: {
            fontWeight: 600,
          },
        },
      },
    },
    Card: {
      defaultProps: {
        // horizontalContentMaxWidth: 160,
        // style: {
        //   color: "white",
        // },
      },
    },
    Clickable: {
      defaultProps: {
        externalIcon: "open-ext",
      },
    },
    Header: {
      defaultProps: {
        color: "primary",
        components: {
          HamburgerMenuToggle: withDefaultProps(Button, {
            variant: "hamburger",
          }),
        },
      },
    },
    HeaderHelpMenu: {
      defaultProps: {
        style: {
          color: "primary",
          backgroundColor: "bjork.20",
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: "24px",
          padding: "0.5rem 1rem",
        },
      },
    },
    // HeaderSearchField: {
    //   defaultProps: {
    //     padding: "1.3rem 2rem 1.3rem 2.875rem",
    //   },
    // },
    HeaderMainMenu: {
      defaultProps: {
        components: {
          Button: withDefaultProps(Button, {
            variant: "primary",
            activeColor: "mynta.main",
          }),
        },
        style: {
          // color: "bjork.20",
        },
      },
    },
    MunicipioPostsModule: {
      defaultProps: {
        itemColor: "gray.100",
      },
    },
    // MunicipioPostsModuleMixedLayout: {
    //   defaultProps: {
    //     collapsedItemProps: {
    //     },
    //   },
    // },
    MunicipioPostsModuleCardItem: {
      defaultProps: {
        metaProps: {
          style: {
            order: -1,
          },
          color: "plommon",
        },
      },
    },
    MunicipioPostsModuleExpandableListLayout: {
      defaultProps: {
        toggleIconProps: {
          size: 5,
        },
      },
    },
    Template: {
      defaultProps: {
        defaultColspan: 8,
      },
    },
    TreeMenu: {
      defaultProps: {
        collapseIcon: "minus",
        expandIcon: "plus",
      },
    },
    TreeMenuItem: {
      defaultProps: {
        hoverColor: "linblomma.80",
        color: "aubergine",
      },
    },
    TreeMenuItemHeader: {
      defaultProps: {
        activeColor: "primary",
      },
    },
    TreeMenuLink: {
      defaultProps: {
        fontWeight: 600,
        fontSize: "18px",
        padding: "1.25rem",
      },
    },
    TreeMenuToggleButton: {
      defaultProps: {
        offset: "1.25rem",
      },
    },
    SlidePanelAction: {
      defaultProps: {
        color: "primary",
      },
    },
    Footer: {
      defaultProps: {
        color: "primary",
        // bottomColor: "white",
        // justifyContent: "center",
        // bannerMargin: 8,
      },
    },
    FooterBlock: {
      defaultProps: {
        components: {
          Link: FooterLink,
        },
      },
    },
    // TypographyBlock: {
    //   defaultProps: {
    //     style: {
    //       color: "white",
    //     },
    //   },
    // },
    MunicipioBillboardModuleController: {
      defaultProps: {
        components: {
          HeroBillboardModule,
          QuoteBillboardModule,
          FeatureBillboardModule,
        },
      },
    },
    MunicipioHeroBillboardModule: {
      defaultProps: {
        contentElementMapping: { p: "preamble" },
        titleVariant: null,
      },
      transformProps: (props) => {
        if (props.format === "hero") {
          delete props.color;
        }
      },
    },
    MunicipioFeatureBillboardModule: {
      defaultProps: {
        contentElementMapping: { p: "body" },
      },
    },

    // Forms
    MunicipioFormModuleController: {
      defaultProps: {
        components: {
          DisclaimerWrapper: Fragment,
          DisclaimerTypographyBlock: { elementMapping: { p: "description" } },
        },
      },
    },

    FormModuleCustomContentField: {
      defaultProps: {
        color: "nougat",
        borderRadius: 1.5,
        padding: [5, 10],
      },
    },
    FormFieldLabel: {
      defaultProps: {
        style: {
          fontSize: "1.1rem",
        },
      },
    },
    FormField: {
      defaultProps: {
        inputProps: {
          style: {
            fontSize: "1.1rem",
            backgroundColor: "transparent",
          },
        },
      },
    },
    PostCardController: {
      defaultProps: {
        visibleFields: { icon: true },
      },
    },

    PostCard: {
      defaultProps: {
        ctaVariant: "primary",
      },
    },
    PageHeading: {
      defaultProps: {
        datesTextColor: "plommon",
      },
    },
    ModularityArea: {
      defaultProps: {
        gap: [12.5, 25],
      },
    },
    PageSection: {
      defaultProps: {
        spacing: [12.5, 25],
      },
    },
    Typography: {
      defaultProps: {
        style: {
          h1: {},
        },
      },
    },
  },
});
