/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import {
  InlineList,
  Clickable,
  useThemeProps,
  handleComponentsProp,
  parseStyle,
} from "@wsui/base";

const HeaderMainMenuRoot = styled("div")``;

const DefaultLink = styled(Clickable)`
  text-decoration: inherit;
  color: inherit;
  font-weight: 600;
  &.wsui-is-interactive:hover,
  &:focus-visible {
    text-decoration: underline;
  }
`;

export default function HeaderMainMenu(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "HeaderMainMenu" });
  let { menu = "HEADER_TABS_MENU", components, style, ...restProps } = props;
  let { Link, List, Button } = handleComponentsProp(components, {
    Link: DefaultLink,
    List: InlineList,
  });
  let items = useMenu(menu)?.items;
  let updatedItems = items.map((item) =>
    item.label === "Bli medlem" ? { ...item, target: "" } : item,
  );

  if (!items?.length) return null;

  return (
    <HeaderMainMenuRoot
      css={css`
        ${parseStyle(style, theme)};
      `}
      {...restProps}
    >
      <List overflow="hidden" alignItems="center" spacing={[4, 8]}>
        {updatedItems.map(({ url, label, target }, index) => {
          return label === "Bli medlem" ? (
            <Button
              url={url}
              target={target}
              css={css`
                @media (max-width: 640px) {
                  display: none;
                }
              `}
            >
              {label}
            </Button>
          ) : (
            <Link url={url} target={target} key={index}>
              {label}
            </Link>
          );
        })}
      </List>
    </HeaderMainMenuRoot>
  );
}
