/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Alert, Typography, Heading, Button } from "@wsui/base";

import Portal from "./../../../../Portal.jsx";

function DefaultReadMore({
  ownerState: {
    alertOwnerState: { color: alertColor },
  },
  children,
  color = "-300",
  ...restProps
}) {
  return (
    <Button
      color={[alertColor, color]}
      style={{
        paddingBlock: 1.5,
        paddingInline: 3,
        marginBlock: -2,
        borderRadius: 1,
      }}
      variant={null}
      {...restProps}
    >
      {children}
    </Button>
  );
}

export default function AlertPopup(props) {
  const {
    id,
    dismissAlertBanner,
    plainTextExcerpt,
    hasPageContent,
    uri,
    readMoreProps,
    ownerState,
    title,
    ...restProps
  } = props;
  return (
    <Portal key={id}>
      <Alert
        onClose={
          !!id &&
          (() => {
            dismissAlertBanner(id);
          })
        }
        color="bjork.20"
        borderRadius={1.5}
        style={{
          padding: 8,
        }}
        css={css`
          width: 700px;

          @media (max-width: 640px) {
            width: 80vw;
          }
        `}
        {...restProps}
      >
        {(alertOwnerState) => (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 1rem;
              flex-wrap: wrap;
              justify-content: start;
            `}
          >
            <Heading variant="h2">{title}</Heading>
            <Typography as="p" variant="body">
              {plainTextExcerpt}
            </Typography>
            {!!(hasPageContent && uri) && (
              <DefaultReadMore
                ownerState={{
                  ...ownerState,
                  alertOwnerState,
                }}
                color="linblomma"
                uri={uri}
                onClick={() => dismissAlertBanner(id)}
                {...readMoreProps}
              />
            )}
          </div>
        )}
      </Alert>
    </Portal>
  );
}
