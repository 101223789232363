import { css } from "@emotion/react";
import React from "react";
import ReactDOM from "react-dom";

export default function Portal({ children }) {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      css={css`
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      >
        {children}
      </div>
    </div>,
    document.querySelector(`#portal`),
  );
}
