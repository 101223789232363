/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import FooterBlockController from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components/FooterBlockController.jsx";
import DefaultLogo from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components/FooterLogo.jsx";
import {
  Link,
  Icon,
  Clickable,
  InlineList,
  PageGrid,
  PageGridItem,
  Stack,
  handleComponentsProp,
  useThemeProps,
} from "@wsui/base";

import logoLararnasakassa from "../../../../images/la-logo.svg";
import logoLararforlaget from "../../../../images/larar-forlaget-logo.svg";
import logoLararfortbildning from "../../../../images/larar-fortbildning-logo.svg";
import logoSverigeslarare from "../../../../images/sveriges-larare-logo.svg";
import swirlsBackgroundAubergine from "../../../../images/swirls-aubergine-background.svg";
import useFooterBlocks from "../../hooks/useFooterBlocks";
import FooterLogo from "../components/FooterLogo.jsx";

export const DefaultLink = styled(Clickable)`
  text-decoration: inherit;
  color: inherit;
  font-family: Hellix;
  font-size: ${({ theme }) => theme.getLength(4)};
  &.wsui-is-interactive:hover,
  &:focus-visible {
    text-decoration: underline;
  }
`;

const DefaultLegalLink = styled(DefaultLink)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.getLength([3.25, 3.75])};
`;

export default function Footer(inProps) {
  const theme = useTheme();
  const {
    color = "primary",
    spacing,
    gap = [7.5, 15],
    blocksMargin = [12, 12],
    bannerMargin = 4,
    components,
    legalMenu = "LEGAL",
    ...restProps
  } = useThemeProps({
    props: inProps,
    name: "Footer",
  });
  let { LegalMenuLink, Logo } = handleComponentsProp(components, {
    LegalMenuLink: DefaultLegalLink,
    Logo: DefaultLogo,
  });

  const footer = useFooterBlocks();

  const {
    columns,
    facebookUrl,
    twitterUrl: xUrl,
    instagramUrl,
    linkedinUrl,
  } = footer;

  let legalMenuItems = useMenu(legalMenu)?.items;

  const logos = [
    {
      logo: logoSverigeslarare,
      link: {
        href: "https://www.sverigeslarare.se/",
        "aria-label": "Sveriges Lärare",
      },
    },
    {
      logo: logoLararfortbildning,
      link: {
        href: "https://www.lararfortbildning.se/",
        "aria-label": "Lärarfortbildning AB",
      },
    },
    {
      logo: logoLararforlaget,
      link: {
        href: "https://lararforlaget.se/",
        "aria-label": "Lärarförlaget",
      },
    },
  ];

  return (
    <footer
      css={css`
        background: ${theme.getColor(color)};
        background-image: url(${swirlsBackgroundAubergine});
        background-repeat: no-repeat;
        background-position: 100% 60%;
        background-size: cover;
        color: ${theme.getColor([color, "text"])};
      `}
      {...restProps}
    >
      <PageGrid
        spacing={spacing}
        rowGap={blocksMargin}
        gap={gap}
        css={css`
          padding-block: ${theme.getLength(blocksMargin)};
        `}
      >
        {columns.map(({ blocks, colspan }, columnIndex) => (
          <PageGridItem key={columnIndex} colspan={[colspan, 12]}>
            <Stack justifyContent="start" direction="column">
              {blocks.map((block, blockIndex) => (
                <FooterBlockController key={blockIndex} block={block} />
              ))}
            </Stack>
          </PageGridItem>
        ))}
      </PageGrid>
      <PageGrid
        css={css`
          padding-block: ${theme.getLength(bannerMargin)};
          display: flex;
          flex-direction: column;
        `}
      >
        <PageGridItem>
          <div
            css={css`
              display: flex;
              gap: 1.2em;
            `}
          >
            {facebookUrl && (
              <Link href={facebookUrl} title="Facebook" aria-label="Facebook">
                <Icon name="facebook" size={6} alt="Facebook" />
              </Link>
            )}
            {xUrl && (
              <Link href={xUrl} title="X" aria-label="X">
                <Icon name="x" size={6} alt="X" />
              </Link>
            )}
            {instagramUrl && (
              <Link
                href={instagramUrl}
                title="Instagram"
                aria-label="Instagram"
              >
                <Icon name="instagram" size={6} alt="Instagram" />
              </Link>
            )}
            {linkedinUrl && (
              <Link href={linkedinUrl} title="LinkedIn" aria-label="LinkedIn">
                <Icon name="linkedin" size={6} alt="LinkedIn" />
              </Link>
            )}
          </div>
        </PageGridItem>
        <PageGridItem>
          <div
            css={css`
              input {
                padding-block: 0.75rem;
                padding-inline: 0.75rem;
                box-sizing: border-box;
                border-radius: 0.25rem;
                border: 1px solid #a49f88;
                box-shadow: none;
                font: inherit;
                color: ##401834;
                font-size: 1.1rem;
                background-color: #fbf9ef;
              }
              button {
                display: none;
              }
            `}
            id="phone2web"
          />
        </PageGridItem>
      </PageGrid>
      <PageGrid
        css={css`
          color: ${theme.getColor([color, "text"])};
        `}
        spacing={spacing}
        // rowGap={blocksMargin}>
      >
        <PageGridItem colspan={12}>
          <div
            css={css`
              padding-block: ${theme.getLength(bannerMargin)};
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              align-items: center;
              @media (max-width: 640px) {
                flex-direction: column-reverse;
                align-items: start;
              }
            `}
          >
            <InlineList
              overflow="visible"
              spacing={[4, 8]}
              css={css`
                flex-shrink: 1;
                @media (max-width: 640px) {
                  padding-bottom: 1rem;
                }
              `}
            >
              {!!legalMenuItems?.length &&
                legalMenuItems.map(({ url, label, target }, index) => {
                  return (
                    <LegalMenuLink url={url} target={target} key={index}>
                      {label}
                    </LegalMenuLink>
                  );
                })}
            </InlineList>
            <Logo
              logo={logoLararnasakassa}
              alt="Lärarnas a-kassa"
              imageStyle={{
                height: "45px",
                maxWidth: "100%",
                minWidth: 0,
                flexShrink: 1,
                "@media (max-width: 640px)": {
                  height: "36px",
                  padding: "3rem 0",
                },
              }}
            />
          </div>
        </PageGridItem>
      </PageGrid>
      <PageGrid
        css={css`
          background-color: ${theme.getColor("plommon")};
          padding-block: ${theme.getLength(8)};
          @media (max-width: 1200px) {
            padding-bottom: ${theme.getLength(20)};
          }
        `}
      >
        <PageGridItem>
          <InlineList spacing={[4, 8]} align="center" alignItems="center">
            {logos ? (
              logos.map((logo, i) => (
                <FooterLogo
                  key={i}
                  logo={logo.logo}
                  link={logo.link}
                  imageStyle={{
                    height: "35px",
                    "@media (max-width: 640px)": {
                      height: "20px",
                    },
                  }}
                />
              ))
            ) : (
              <Logo
                css={css`
                  max-width: 100%;
                  flex-shrink: 1;
                  min-width: 0;
                `}
              />
            )}
          </InlineList>
        </PageGridItem>
      </PageGrid>
    </footer>
  );
}
