exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (
    prevLocation?.pathname === "/boka-samtal/" ||
    prevLocation?.pathname === "/boka-samtal/delta-i-videomöte/"
  ) {
    if (typeof vngage !== "undefined") {
      console.log("triggering vngage.removeAllBanners(true)");
      // eslint-disable-next-line no-undef
      vngage.removeAllBanners?.(true);
    }
  }
  if (
    location.pathname === "/boka-samtal/" ||
    location.pathname === "/boka-samtal/delta-i-videomöte/"
  ) {
    if (typeof vngage !== "undefined") {
      console.log("triggering vngage.evaluateOpportunities();");
      // eslint-disable-next-line no-undef
      vngage.evaluateOpportunities?.();
      //vngage.triggerOpportunity?.("ADFCFB5D-8BC9-4BFA-BFD7-8FB4E9EAEDCC");
    }
  }
};
