/** @jsx jsx */
import { jsx, css, useTheme } from "@emotion/react";
import {
  useThemeProps,
  Button,
  Heading,
  TypographyBlock,
  Section,
  Card,
  parseStyle,
  CardContent,
  Stack,
  InlineList,
  Icon,
} from "@wsui/base";
import { omit } from "lodash/fp";
import { Fragment } from "react";

FeatureBillboardModule.wsuiConfig = {
  hasCaption: true,
};

export default function FeatureBillboardModule(props) {
  const theme = useTheme();
  props = useThemeProps({
    props,
    name: "MunicipioFeatureBillboardModule",
  });
  let {
    content,
    links,
    image,
    imageAspectRatio = 16 / 9,
    imagePlacement = "left",
    title,
    style,
    titleVariant = "caption",
    horizontalContentMaxWidth = "100%",
    mediaColspan = 6,
    buttonProps,
    contentGap = 5,
    contentElementMapping = { p: "description" },
    beforeTitle = null,
    cardContentProps = {},
    icon,
    ...restProps
  } = omit(["color", "align", "buttonMarginTop", "headingVariant"], props);

  let MaybeSection = title ? Section : Fragment;

  return (
    <Card
      color="transparent"
      image={image}
      mediaColspan={mediaColspan}
      mediaAspectRatio={imageAspectRatio}
      mirrored={imagePlacement === "right"}
      css={css`
        ${parseStyle(style, theme)}
      `}
      horizontalContentMaxWidth={horizontalContentMaxWidth}
      {...restProps}
    >
      <CardContent {...cardContentProps}>
        <span
          css={css`
            display: flex;
            gap: 1rem;
            @media (max-width: 640px) {
              flex-direction: column;
            }
          `}
        >
          {icon && <Icon name={icon} size={20} css={{ flex: "none" }} />}
          <Stack spacing={contentGap}>
            {beforeTitle}
            {!!title && <Heading variant={titleVariant}>{title}</Heading>}
            <MaybeSection>
              <TypographyBlock
                maxBlockMargin={contentGap}
                elementMapping={contentElementMapping}
              >
                {content}
              </TypographyBlock>
              {!!links?.length && (
                <InlineList>
                  {links.map(({ link, variant = "primary" }, index) => (
                    <Button
                      key={index}
                      {...link}
                      variant={variant}
                      {...buttonProps}
                    />
                  ))}
                </InlineList>
              )}
            </MaybeSection>
          </Stack>
        </span>
      </CardContent>
    </Card>
  );
}
