/** @jsx jsx */
import { jsx } from "@emotion/react";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { PageSection } from "@wsui/base";

import Image from "./Image.jsx";

export default function PageFeaturedImage({ ...restProps }) {
  const pageContext = usePageContext();

  if (!pageContext?.displaySettings?.postSingleShowFeaturedImage) {
    return null;
  }

  const featuredImage = pageContext?.featuredImage?.node;

  if (!featuredImage) {
    return null;
  }
  let strippedCaption = featuredImage.caption?.replace(/(<([^>]+)>)/gi, "");

  return (
    <PageSection background={null} spacing={0} spacingEnd={5}>
      <Image
        {...featuredImage}
        caption={strippedCaption}
        credit={featuredImage.credit}
        aspectRatio={1440 / 710}
        borderRadius={0}
        {...restProps}
      />
    </PageSection>
  );
}
