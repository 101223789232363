/** @jsx jsx */
import { jsx, css, useTheme } from "@emotion/react";
import { Link, Typography, parseStyle } from "@wsui/base";
import Img from "gatsby-image";
import { Fragment, useState, useEffect } from "react";
import { useElementSize } from "usehooks-ts";

export default function Image({
  alt,
  aspectRatio,
  base64,
  caption,
  captionProps = {},
  credit,
  creditProps = {},
  // eslint-disable-next-line no-unused-vars
  estimatedWidth = 320,
  height,
  imgProps: { style: imgStyle, ...imgRestProps } = {},
  linkProps = {},
  linkTo,
  maxWidth,
  src,
  srcSet,
  srcSetWebp,
  srcWebp,
  width,
  borderRadius,
  WrapperComponent = null,
  hideCaption = false,
  focalPoint,
  ...restProps
}) {
  const theme = useTheme();
  const [squareRef, { height: componentHeight, width: componentWidth }] =
    useElementSize();

  if (WrapperComponent == null) {
    WrapperComponent = (caption || credit) && !hideCaption ? "figure" : "div";
  }

  const [, focalPointTopPosition] = focalPoint?.split(" ") ?? [
    undefined,
    undefined,
  ];

  const getDecimalFromPercentString = (percentString) => {
    const percent = percentString
      ? Number.parseInt(percentString.replace("%"))
      : NaN;

    if (isNaN(percent)) {
      return undefined;
    }
    return 0.01 * percent;
  };

  const [topPosition, setTopPosition] = useState(undefined);
  const [topFocalPoint, setTopFocalPoint] = useState(undefined);

  useEffect(() => {
    const tempTopFocalPoint = getDecimalFromPercentString(
      focalPointTopPosition,
    );
    const centerOfComponent = componentHeight / 2;
    const componentImageWidthRatio = width / componentWidth;
    const finalImageHeight = height / componentImageWidthRatio;
    const tempTopPosition =
      centerOfComponent - finalImageHeight * tempTopFocalPoint;
    const remainderFromBottom = finalImageHeight - Math.abs(tempTopPosition);
    const tooFarUp = remainderFromBottom < componentHeight;

    setTopPosition(
      tooFarUp ? -(finalImageHeight - componentHeight) : tempTopPosition,
    );
    setTopFocalPoint(tempTopFocalPoint);
  }, [focalPointTopPosition, componentHeight, componentWidth, height, width]);

  return (
    <Fragment>
      <WrapperComponent
        css={css`
          margin: 0;
          ${!!maxWidth && `max-width: ${maxWidth}px;`}
          ${aspectRatio && `aspect-ratio: ${aspectRatio};overflow: hidden;`}
        `}
        ref={squareRef}
        {...restProps}
      >
        <Link to={linkTo} {...linkProps}>
          <Img
            css={css`
              border-radius: ${theme.getLength(borderRadius)};
              ${!!topPosition && `top: ${Math.min(topPosition, 0)}px;`}
              ${parseStyle(imgStyle, theme)}
            > picture {
                top: 100%;
              }
            `}
            fluid={{
              src,
              srcSet,
              srcWebp,
              srcSetWebp,
              sizes: `${componentWidth}px`,
              aspectRatio: width / height,
              width,
              height,
              base64,
              alt,
            }}
            alt={alt}
            imgStyle={
              topFocalPoint
                ? {
                    objectPosition: focalPoint,
                  }
                : undefined
            }
            {...imgRestProps}
          />
        </Link>
      </WrapperComponent>
      {!!(caption || credit) && !hideCaption && (
        <figcaption
          css={css`
            margin-top: 1.9rem;
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 0.25rem 1rem;

            @media (max-width: 640px) {
              flex-direction: column;
            }
          `}
          {...captionProps}
        >
          {!!(credit || caption) && (
            <Fragment>
              <Typography
                as="p"
                {...creditProps}
                css={css`
                  width: 30%;
                  @media (max-width: 640px) {
                    width: 100%;
                  }
                `}
                variant="h4"
              >
                {caption}
              </Typography>
              <Typography as="p" variant="h4">
                {credit && "Fotograf: " + credit}
              </Typography>
            </Fragment>
          )}
        </figcaption>
      )}
    </Fragment>
  );
}
