/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Icon, useThemeProps } from "@wsui/base";
import { FeatureBillboardModule } from "@wsui/municipio";

QuoteBillboardModule.wsuiConfig = {
  hasCaption: false,
};

export default function QuoteBillboardModule(props) {
  props = useThemeProps({
    props,
    name: "MunicipioQuoteBillboardModule",
  });
  return (
    <FeatureBillboardModule
      contentElementMapping={{ p: "quote" }}
      beforeTitle={<Icon name="quote" size={16.25} />}
      contentGap={7.5}
      mediaBelow={true}
      {...props}
    />
  );
}
