/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Clickable } from "@wsui/base";

export default function FooterLogo({ link, logo, imageStyle, ...restProps }) {
  link = typeof link === "string" ? { href: link } : link;
  return (
    <div {...restProps}>
      <Clickable
        {...link}
        css={css`
          color: inherit;
          text-decoration: inherit;
        `}
      >
        <img
          src={logo}
          alt=""
          css={css`
            ${imageStyle}
          `}
        />
      </Clickable>
    </div>
  );
}
